import { default as indexMScMo3Bi82Meta } from "/opt/apps/web/pages/index.vue?macro=true";
import { default as indexOJthvvQICIMeta } from "/opt/apps/web/pages/[place]/[parking]/index.vue?macro=true";
import { default as reservation78rD0Na45AMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation.vue?macro=true";
import { default as optionsNbWvMnAJvdMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue?macro=true";
import { default as index4Js8ZpGldgMeta } from "/opt/apps/web/pages/[place]/index.vue?macro=true";
import { default as _91slug_93O6AhxQoAhnMeta } from "/opt/apps/web/pages/blog/[slug].vue?macro=true";
import { default as index98QeOwUcAaMeta } from "/opt/apps/web/pages/blog/index.vue?macro=true";
import { default as _91page_932UxFcpIw7PMeta } from "/opt/apps/web/pages/blog/pages/[page].vue?macro=true";
import { default as billingKbwuMOVndIMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/billing.vue?macro=true";
import { default as detailsqxjFM32W0KMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/details.vue?macro=true";
import { default as indexM4QM0mzyG0Meta } from "/opt/apps/web/pages/bookings/[booking]/index.vue?macro=true";
import { default as manage36CTewKD4FMeta } from "/opt/apps/web/pages/bookings/manage.vue?macro=true";
import { default as remindNm1DafxZJqMeta } from "/opt/apps/web/pages/bookings/remind.vue?macro=true";
import { default as contactvvuKCl6KMqMeta } from "/opt/apps/web/pages/contact.vue?macro=true";
import { default as confirmnICy3oYLiTMeta } from "/opt/apps/web/pages/customer/auth/confirm.vue?macro=true";
import { default as login91h2PlO8lmMeta } from "/opt/apps/web/pages/customer/auth/login.vue?macro=true";
import { default as registerunqX1iDHCFMeta } from "/opt/apps/web/pages/customer/auth/register.vue?macro=true";
import { default as remindMCWxjawFBZMeta } from "/opt/apps/web/pages/customer/auth/remind.vue?macro=true";
import { default as index3gbo3Mz4syMeta } from "/opt/apps/web/pages/customer/bookings/index.vue?macro=true";
import { default as index8US3NjamWtMeta } from "/opt/apps/web/pages/customer/extras/index.vue?macro=true";
import { default as indexkakOF1Vn9KMeta } from "/opt/apps/web/pages/customer/index.vue?macro=true";
import { default as index32ThOmjcByMeta } from "/opt/apps/web/pages/customer/settings/index.vue?macro=true";
import { default as passwordIoSvqLeejtMeta } from "/opt/apps/web/pages/customer/settings/password.vue?macro=true";
import { default as indexXeZ93U622aMeta } from "/opt/apps/web/pages/customer/vehicles/index.vue?macro=true";
import { default as _91category_93bvu4iHBcIqMeta } from "/opt/apps/web/pages/faq/[category].vue?macro=true";
import { default as indexhZWYNULP0yMeta } from "/opt/apps/web/pages/faq/index.vue?macro=true";
import { default as searchDuVVLezbP7Meta } from "/opt/apps/web/pages/faq/search.vue?macro=true";
import { default as gps5yVmzUQYKRMeta } from "/opt/apps/web/pages/gps.vue?macro=true";
import { default as extras_45airclaimsWov3U3KAsLMeta } from "/opt/apps/web/pages/others/extras-airclaims.vue?macro=true";
import { default as extras_45carrentalsJ1CBQehrSIMeta } from "/opt/apps/web/pages/others/extras-carrentals.vue?macro=true";
import { default as extras_45travelinsuranceXpT92s2NIYMeta } from "/opt/apps/web/pages/others/extras-travelinsurance.vue?macro=true";
import { default as howitworksE15LWoqtyOMeta } from "/opt/apps/web/pages/others/howitworks.vue?macro=true";
import { default as howtoselectcOWQYgrDRpMeta } from "/opt/apps/web/pages/others/howtoselect.vue?macro=true";
import { default as rules_45privacy2aML1pbIzVMeta } from "/opt/apps/web/pages/others/rules-privacy.vue?macro=true";
import { default as rules_45termsPzHTewMHRIMeta } from "/opt/apps/web/pages/others/rules-terms.vue?macro=true";
import { default as whywe6d6oRgk7FhMeta } from "/opt/apps/web/pages/others/whywe.vue?macro=true";
import { default as airportsn5yXkl66uzMeta } from "/opt/apps/web/pages/parkings/airports.vue?macro=true";
import { default as stationsJlib5hFFrHMeta } from "/opt/apps/web/pages/parkings/stations.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "place-parking___en",
    path: "/en/:place()/:parking()/",
    meta: indexOJthvvQICIMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/index.vue")
  },
  {
    name: "place-parking___pl",
    path: "/pl/:place()/:parking()/",
    meta: indexOJthvvQICIMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/index.vue")
  },
  {
    name: "place-parking-reservation___en",
    path: "/en/:place()/:parking()/reservation/",
    meta: reservation78rD0Na45AMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation.vue")
  },
  {
    name: "place-parking-reservation___pl",
    path: "/pl/:place()/:parking()/reservation/",
    meta: reservation78rD0Na45AMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation.vue")
  },
  {
    name: "place-parking-reservation2-options___en",
    path: "/en/:place()/:parking()/reservation2/options/",
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue")
  },
  {
    name: "place-parking-reservation2-options___pl",
    path: "/pl/:place()/:parking()/reservation2/options/",
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue")
  },
  {
    name: "place___en",
    path: "/en/:place()/",
    meta: index4Js8ZpGldgMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/index.vue")
  },
  {
    name: "place___pl",
    path: "/pl/:place()/",
    meta: index4Js8ZpGldgMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/index.vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()/",
    meta: _91slug_93O6AhxQoAhnMeta || {},
    component: () => import("/opt/apps/web/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___pl",
    path: "/pl/blog/:slug()/",
    meta: _91slug_93O6AhxQoAhnMeta || {},
    component: () => import("/opt/apps/web/pages/blog/[slug].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog/",
    meta: index98QeOwUcAaMeta || {},
    component: () => import("/opt/apps/web/pages/blog/index.vue")
  },
  {
    name: "blog___pl",
    path: "/pl/blog/",
    meta: index98QeOwUcAaMeta || {},
    component: () => import("/opt/apps/web/pages/blog/index.vue")
  },
  {
    name: "blog-pages-page___en",
    path: "/en/blog/pages/:page()/",
    meta: _91page_932UxFcpIw7PMeta || {},
    component: () => import("/opt/apps/web/pages/blog/pages/[page].vue")
  },
  {
    name: "blog-pages-page___pl",
    path: "/pl/blog/pages/:page()/",
    meta: _91page_932UxFcpIw7PMeta || {},
    component: () => import("/opt/apps/web/pages/blog/pages/[page].vue")
  },
  {
    name: "bookings-booking-edit-billing___en",
    path: "/en/bookings/:booking()/edit/billing/",
    meta: billingKbwuMOVndIMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/billing.vue")
  },
  {
    name: "bookings-booking-edit-billing___pl",
    path: "/pl/bookings/:booking()/edit/billing/",
    meta: billingKbwuMOVndIMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/billing.vue")
  },
  {
    name: "bookings-booking-edit-details___en",
    path: "/en/bookings/:booking()/edit/details/",
    meta: detailsqxjFM32W0KMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/details.vue")
  },
  {
    name: "bookings-booking-edit-details___pl",
    path: "/pl/bookings/:booking()/edit/details/",
    meta: detailsqxjFM32W0KMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/details.vue")
  },
  {
    name: "bookings-booking___en",
    path: "/en/bookings/:booking()/",
    meta: indexM4QM0mzyG0Meta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/index.vue")
  },
  {
    name: "bookings-booking___pl",
    path: "/pl/bookings/:booking()/",
    meta: indexM4QM0mzyG0Meta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/index.vue")
  },
  {
    name: "bookings-manage___en",
    path: "/en/bookings/manage/",
    meta: manage36CTewKD4FMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/manage.vue")
  },
  {
    name: "bookings-manage___pl",
    path: "/pl/bookings/manage/",
    meta: manage36CTewKD4FMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/manage.vue")
  },
  {
    name: "bookings-remind___en",
    path: "/en/bookings/remind/",
    meta: remindNm1DafxZJqMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/remind.vue")
  },
  {
    name: "bookings-remind___pl",
    path: "/pl/bookings/remind/",
    meta: remindNm1DafxZJqMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/remind.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact/",
    meta: contactvvuKCl6KMqMeta || {},
    component: () => import("/opt/apps/web/pages/contact.vue")
  },
  {
    name: "contact___pl",
    path: "/pl/kontakt/",
    meta: contactvvuKCl6KMqMeta || {},
    component: () => import("/opt/apps/web/pages/contact.vue")
  },
  {
    name: "customer-auth-confirm___en",
    path: "/en/customer/auth/confirm/",
    component: () => import("/opt/apps/web/pages/customer/auth/confirm.vue")
  },
  {
    name: "customer-auth-confirm___pl",
    path: "/pl/customer/auth/confirm/",
    component: () => import("/opt/apps/web/pages/customer/auth/confirm.vue")
  },
  {
    name: "customer-auth-login___en",
    path: "/en/customer/auth/login/",
    meta: login91h2PlO8lmMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/login.vue")
  },
  {
    name: "customer-auth-login___pl",
    path: "/pl/customer/auth/login/",
    meta: login91h2PlO8lmMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/login.vue")
  },
  {
    name: "customer-auth-register___en",
    path: "/en/customer/auth/register/",
    meta: registerunqX1iDHCFMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/register.vue")
  },
  {
    name: "customer-auth-register___pl",
    path: "/pl/customer/auth/register/",
    meta: registerunqX1iDHCFMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/register.vue")
  },
  {
    name: "customer-auth-remind___en",
    path: "/en/customer/auth/remind/",
    meta: remindMCWxjawFBZMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/remind.vue")
  },
  {
    name: "customer-auth-remind___pl",
    path: "/pl/customer/auth/remind/",
    meta: remindMCWxjawFBZMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/remind.vue")
  },
  {
    name: "customer-bookings___en",
    path: "/en/customer/bookings/",
    meta: index3gbo3Mz4syMeta || {},
    component: () => import("/opt/apps/web/pages/customer/bookings/index.vue")
  },
  {
    name: "customer-bookings___pl",
    path: "/pl/customer/bookings/",
    meta: index3gbo3Mz4syMeta || {},
    component: () => import("/opt/apps/web/pages/customer/bookings/index.vue")
  },
  {
    name: "customer-extras___en",
    path: "/en/customer/extras/",
    meta: index8US3NjamWtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/extras/index.vue")
  },
  {
    name: "customer-extras___pl",
    path: "/pl/customer/extras/",
    meta: index8US3NjamWtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/extras/index.vue")
  },
  {
    name: "customer___en",
    path: "/en/customer/",
    meta: indexkakOF1Vn9KMeta || {},
    component: () => import("/opt/apps/web/pages/customer/index.vue")
  },
  {
    name: "customer___pl",
    path: "/pl/customer/",
    meta: indexkakOF1Vn9KMeta || {},
    component: () => import("/opt/apps/web/pages/customer/index.vue")
  },
  {
    name: "customer-settings___en",
    path: "/en/customer/settings/",
    meta: index32ThOmjcByMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/index.vue")
  },
  {
    name: "customer-settings___pl",
    path: "/pl/customer/settings/",
    meta: index32ThOmjcByMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/index.vue")
  },
  {
    name: "customer-settings-password___en",
    path: "/en/customer/settings/password/",
    meta: passwordIoSvqLeejtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/password.vue")
  },
  {
    name: "customer-settings-password___pl",
    path: "/pl/customer/settings/password/",
    meta: passwordIoSvqLeejtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/password.vue")
  },
  {
    name: "customer-vehicles___en",
    path: "/en/customer/vehicles/",
    meta: indexXeZ93U622aMeta || {},
    component: () => import("/opt/apps/web/pages/customer/vehicles/index.vue")
  },
  {
    name: "customer-vehicles___pl",
    path: "/pl/customer/vehicles/",
    meta: indexXeZ93U622aMeta || {},
    component: () => import("/opt/apps/web/pages/customer/vehicles/index.vue")
  },
  {
    name: "faq-category___en",
    path: "/en/faq/:category()/",
    meta: _91category_93bvu4iHBcIqMeta || {},
    component: () => import("/opt/apps/web/pages/faq/[category].vue")
  },
  {
    name: "faq-category___pl",
    path: "/pl/faq/:category()/",
    meta: _91category_93bvu4iHBcIqMeta || {},
    component: () => import("/opt/apps/web/pages/faq/[category].vue")
  },
  {
    name: "faq___en",
    path: "/en/faq/",
    meta: indexhZWYNULP0yMeta || {},
    component: () => import("/opt/apps/web/pages/faq/index.vue")
  },
  {
    name: "faq___pl",
    path: "/pl/faq/",
    meta: indexhZWYNULP0yMeta || {},
    component: () => import("/opt/apps/web/pages/faq/index.vue")
  },
  {
    name: "faq-search___en",
    path: "/en/faq/search/",
    meta: searchDuVVLezbP7Meta || {},
    component: () => import("/opt/apps/web/pages/faq/search.vue")
  },
  {
    name: "faq-search___pl",
    path: "/pl/faq/search/",
    meta: searchDuVVLezbP7Meta || {},
    component: () => import("/opt/apps/web/pages/faq/search.vue")
  },
  {
    name: "gps___en",
    path: "/en/gps/",
    meta: gps5yVmzUQYKRMeta || {},
    component: () => import("/opt/apps/web/pages/gps.vue")
  },
  {
    name: "gps___pl",
    path: "/pl/gps/",
    meta: gps5yVmzUQYKRMeta || {},
    component: () => import("/opt/apps/web/pages/gps.vue")
  },
  {
    name: "index___en",
    path: "/en/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "others-extras-airclaims___en",
    path: "/en/others/extras-airclaims/",
    meta: extras_45airclaimsWov3U3KAsLMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-airclaims.vue")
  },
  {
    name: "others-extras-airclaims___pl",
    path: "/pl/others/extras-airclaims/",
    meta: extras_45airclaimsWov3U3KAsLMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-airclaims.vue")
  },
  {
    name: "others-extras-carrentals___en",
    path: "/en/car-rentals/",
    meta: extras_45carrentalsJ1CBQehrSIMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-carrentals.vue")
  },
  {
    name: "others-extras-carrentals___pl",
    path: "/pl/wynajem-pojazdow/",
    meta: extras_45carrentalsJ1CBQehrSIMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-carrentals.vue")
  },
  {
    name: "others-extras-travelinsurance___en",
    path: "/en/travel-insurance/",
    meta: extras_45travelinsuranceXpT92s2NIYMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-travelinsurance.vue")
  },
  {
    name: "others-extras-travelinsurance___pl",
    path: "/pl/ubezpieczenia-podrozne/",
    meta: extras_45travelinsuranceXpT92s2NIYMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-travelinsurance.vue")
  },
  {
    name: "others-howitworks___en",
    path: "/en/how-it-works/",
    meta: howitworksE15LWoqtyOMeta || {},
    component: () => import("/opt/apps/web/pages/others/howitworks.vue")
  },
  {
    name: "others-howitworks___pl",
    path: "/pl/jak-to-dziala/",
    meta: howitworksE15LWoqtyOMeta || {},
    component: () => import("/opt/apps/web/pages/others/howitworks.vue")
  },
  {
    name: "others-howtoselect___en",
    path: "/en/how-to-choose-place-parking/",
    meta: howtoselectcOWQYgrDRpMeta || {},
    component: () => import("/opt/apps/web/pages/others/howtoselect.vue")
  },
  {
    name: "others-howtoselect___pl",
    path: "/pl/jak-wybrac-parking-lotniskowy/",
    meta: howtoselectcOWQYgrDRpMeta || {},
    component: () => import("/opt/apps/web/pages/others/howtoselect.vue")
  },
  {
    name: "others-rules-privacy___en",
    path: "/en/privacy-policy/",
    meta: rules_45privacy2aML1pbIzVMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-privacy.vue")
  },
  {
    name: "others-rules-privacy___pl",
    path: "/pl/polityka-prywatnosci/",
    meta: rules_45privacy2aML1pbIzVMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-privacy.vue")
  },
  {
    name: "others-rules-terms___en",
    path: "/en/rules/",
    meta: rules_45termsPzHTewMHRIMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-terms.vue")
  },
  {
    name: "others-rules-terms___pl",
    path: "/pl/regulamin/",
    meta: rules_45termsPzHTewMHRIMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-terms.vue")
  },
  {
    name: "others-whywe___en",
    path: "/en/why-nextpark/",
    meta: whywe6d6oRgk7FhMeta || {},
    component: () => import("/opt/apps/web/pages/others/whywe.vue")
  },
  {
    name: "others-whywe___pl",
    path: "/pl/dlaczego-nextpark/",
    meta: whywe6d6oRgk7FhMeta || {},
    component: () => import("/opt/apps/web/pages/others/whywe.vue")
  },
  {
    name: "parkings-airports___en",
    path: "/en/airport-parkings/",
    meta: airportsn5yXkl66uzMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/airports.vue")
  },
  {
    name: "parkings-airports___pl",
    path: "/pl/parkingi-lotniskowe/",
    meta: airportsn5yXkl66uzMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/airports.vue")
  },
  {
    name: "parkings-stations___en",
    path: "/en/parking-station/",
    meta: stationsJlib5hFFrHMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/stations.vue")
  },
  {
    name: "parkings-stations___pl",
    path: "/pl/parking-dworzec/",
    meta: stationsJlib5hFFrHMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/stations.vue")
  }
]